
import { defineComponent } from 'vue';
import { CosmicService } from '@/service/Cosmic.service';
import { Category } from '@/service/Cosmic.model';

interface Data {
  categories: Category[];
}

export default defineComponent({
  name: 'Menu',
  data(): Data {
    return {
      categories: [],
    };
  },
  mounted() {
    this.loadCategories();
  },
  methods: {
    loadCategories() {
      CosmicService.getObjects<Category>({ type: 'categories', props: 'title,slug' })
        .then((categories) => {
          this.$data.categories = categories;
        });
    },
  },
});
