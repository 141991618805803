<template>
  <footer>
    © 2021 Trasgo - Muebles y Madera. Powered by <a href="https://tresases.cl" title="3 Ases" target="_blank" rel="nofollow noopener">Tres Ases</a>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
});
</script>

<style scoped>
footer {
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
}
</style>
